export const theme = {
    app_black: '#0D0C0B',
    app_orange: '#FF9D00',
    app_grey: '#A0A0A0',
    app_grey_light: '#E3E4E4',
    app_red_error: '#ec3f34',

    fontWeight: {
        regular: 400,
        medium: 500,
        bold: 700,
    },
    fontSize: {
        s: '1.2rem',
        l: '1.5rem',
        xl: '1.9rem',
        xxl: '2.5rem',
    }
};