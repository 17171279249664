import styled from "styled-components";

export const ModuleSpinnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  justify-items: center;
  align-items: center;
  display: flex;
  background-color: whitesmoke;
  position:absolute;
  z-index: 10;
  opacity: 0.7;
  top: 0;
  left: 0;
`;