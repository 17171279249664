import {ACTION_TYPES} from '../actions/actionTypes'
import _ from "lodash";


const initialState = {
    authError: false,
    isLoading: true,
    isModuleEditing: false,
    isUserLogged: false,
    isError: false,
    navigationItemController: [],
    loginButtonDisabled: false,
    isModuleLoading: false,
};

const rootReducer = (state = initialState, {type, payload}) => {
    switch (type) {

        // --- LOGOUT ---
        case ACTION_TYPES.LOGOUT:
            localStorage.removeItem('AUTH');
            return initialState;

        // --- AUTH ---
        case ACTION_TYPES.AUTHENTICATE_ON_FOCUS :
            return {
                ...state,
                loginButtonDisabled: false,
                authError: false,
            };

        case ACTION_TYPES.AUTHENTICATE_REQUEST :
            return {
                ...state,
                loginButtonDisabled: true,
                authError: false,
            };

        case ACTION_TYPES.AUTHENTICATE_SUCCESS :
            return {
                ...state,
                authError: false,
                isUserLogged: true,
                loginButtonDisabled: false,
            };

        case ACTION_TYPES.AUTHENTICATE_FAILURE :
            return {
                ...state,
                authError: true,
                loginButtonDisabled: false,
            };

        // --- MENU ---
        case ACTION_TYPES.MENU_FETCH_SUCCESS :
            return {
                ...state,
                navigationItemsList: payload,
                navigationItemController: [payload[0]],
                isLoading: false,
            };

        case ACTION_TYPES.MENU_FETCH_AFTER_EDIT :
            return {
                ...state,
                navigationItemsList: payload,
                isLoading: false,
            };

        // --- FETCH ---
        case ACTION_TYPES.FETCH_REQUEST :
            return {
                ...state,
                isLoading: true,
            };

        case ACTION_TYPES.EDIT_MODULE_REQUEST :
            return {
                ...state,
                isModuleLoading: payload,
            };

        case ACTION_TYPES.EDIT_MODULE_RESPONSE :
            return {
                ...state,
                isModuleLoading: false,
            };

        case ACTION_TYPES.FETCH_MODULES_SUCCESS:

            let sortedModules = payload.modules.sort((a, b) => {
                return a.order - b.order;
            });

            let newNavigationItemController = state.navigationItemController;
            if(newNavigationItemController.length){
                newNavigationItemController[newNavigationItemController.length - 1] = {...payload, modules: sortedModules};
            }

            return {
                ...state,
                navigationItemController: newNavigationItemController,
                isLoading: false,
            };

        case ACTION_TYPES.FETCH_FAILURE :
            return {
                ...state,
                isLoading: false,
            };

        // --- MODULES ---
        case ACTION_TYPES.TOGGLE_MODULE_EDIT :

            let newState = null;

            if (payload) {
                newState = {
                    ...state,
                    isModuleEditing: payload,
                }
            } else {

                newState = {
                    ...state,
                    isModuleEditing: payload,
                    moduleEditableData: undefined,
                }
            }

            return newState;

        case ACTION_TYPES.EDIT_MODULE_DATA :
            return {
                ...state,
                moduleEditableData: payload,
            };


        case ACTION_TYPES.ADD_MODULE :
            return {
                ...state,
                moduleToAdd: payload,
                isModuleEditing: true,
            };

        case ACTION_TYPES.SAVE_CREATED_MODULE :
            return {
                ...state,
                moduleToAdd: undefined,
                isModuleEditing: false,
            };

        case ACTION_TYPES.MODULE_CANCEL_ADDING :
            return {
                ...state,
                moduleToAdd: undefined,
                isModuleEditing: false,
                moduleEditableData: undefined,
            };


        // --- MODAL ---
        case ACTION_TYPES.SET_API_ERROR :
            return {
                ...state,
                isError: payload
            };

        // --- MENU ---
        case ACTION_TYPES.SET_ALL_NAVIGATION_ITEMS :
            return {
                ...state,
                allNavigationItems: payload,
            };

        // --- FILES ---
        case ACTION_TYPES.IMPORT_FILES :
            return {
                ...state,
                fetchedFiles: {
                    data: payload.data,
                },
            };
        case ACTION_TYPES.CLEAR_IMPORTED_FILES :
            return {
                ...state,
                fetchedFiles: undefined
            };

        // --- REDIRECT ---
        case ACTION_TYPES.REDIRECT_STACK_PUSH :

            let stack = [...state.navigationItemController];
            stack.push(payload);

            return {
                ...state,
                navigationItemController: stack
            };

        case ACTION_TYPES.REDIRECT_STACK_RESET :
            let newStack = [];
            newStack.push(payload);

            return {
                ...state,
                navigationItemController: newStack
            };

        case ACTION_TYPES.REDIRECT_STACK_SET :
            return {
                ...state,
                navigationItemController: payload
            };

        case ACTION_TYPES.REDIRECT_STACK_CURRENT_EDIT :

            newNavigationItemController = _.cloneDeep(state.navigationItemController);
            newNavigationItemController[newNavigationItemController.length -1] = payload;

            return {
                ...state,
                navigationItemController: newNavigationItemController
            };

        case ACTION_TYPES.REDIRECT_STACK_POP :
            stack = [...state.navigationItemController];
            stack.pop();

            return {
                ...state,
                navigationItemController: stack
            };

        // --- ADD NEW NAVIGATION ITEM ---
        case ACTION_TYPES.SET_NEW_NAVIGATION_ITEM :
            return {
                ...state,
                newNavigationItem: payload
            };

        default:
            return state;
    }
};

export default rootReducer;
