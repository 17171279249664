import React, {useRef} from "react";
import {MODULES} from "../../../utils/modules";
import ActionContainer from "../../molecules/ActionContainer";
import {ModuleWrapper} from "../../atoms/wrappers/ModuleWrapper";
import {HorizontalModuleWrapper} from "../../atoms/wrappers/HorizontalModuleWrapper";
import {ModuleContentWrapper} from "../../atoms/wrappers/ModuleContentWrapper";
import {ModuleHeading} from "../../atoms/headings/ModuleHeading";
import {useLocation} from "react-router-dom";
import {ACTION_TYPES} from "../../../actions/actionTypes";
import {useDispatch, useSelector} from "react-redux";
import {addItem} from "../../../actions";
import {getLastElement, getModuleId} from "../../../utils/moduleFunctions";
import {useDrag, useDrop} from "react-dnd";
import {DnDWrapper} from "../../atoms/wrappers/DnDWrapper";


const FilterModule = ({module, index, id, removeModuleTrigger, disabled: isDisabled, moveCard,canModuleDrag,saveNavigationItemOrder}) => {

    //  ############### DnD ###############
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'MODULE',
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) return;
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex
        },
    });
    const [{isDragging}, drag] = useDrag({
        item: {type: 'MODULE', id, index},
        canDrag: canModuleDrag,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => saveNavigationItemOrder(),
    });
    drag(drop(ref));

    let location = useLocation();
    const dispatch = useDispatch();

    const currentNavItem = useSelector(state => {
            if (state.navigationItemController && state.navigationItemController.length)
                return getLastElement(state.navigationItemController);
        }
    );

    // --- GET TYPE FROM ENUM ---
    const getModuleType = () => MODULES[module.type.toUpperCase()];


    // --- EDIT REJECTED ---
    const resetValuesToDefault = () => {
        //no data to reset in this module.
        dispatch({type: ACTION_TYPES.MODULE_CANCEL_ADDING});
    };

    // --- SAVE ACTION ---
    const saveModuleTrigger = () => {
        dispatch(addItem(getModuleId(location), module, currentNavItem.modules));
        dispatch({type: ACTION_TYPES.SAVE_CREATED_MODULE});
    };

    const opacity = isDragging ? 0 : 1;
    const getRef = () => !isDisabled? null : ref;

    return (
        <DnDWrapper ref={getRef()} style={{opacity}}>
            <ModuleHeading canModuleDrag={canModuleDrag} isDisabled={isDisabled}>
                {getModuleType().UI_NAME}
            </ModuleHeading>
            <ModuleWrapper isSmall>
                <ModuleContentWrapper style={isDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                    <HorizontalModuleWrapper>
                        <p>Moduł pozwalający na filtrowanie zawartości</p>
                    </HorizontalModuleWrapper>
                </ModuleContentWrapper>
                <ActionContainer isDisabled={isDisabled}
                                 removeModuleTrigger={removeModuleTrigger} idToRemove={module._id}
                                 noAction={true}
                                 resetValuesToDefault={resetValuesToDefault}
                                 saveModuleTrigger={saveModuleTrigger}/>
            </ModuleWrapper>
        </DnDWrapper>
    )
};
export default FilterModule;