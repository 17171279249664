import styled from "styled-components";
import LoginParagraph from "../atoms/paragraphs/LoginParagraph";
import React from "react";
import {ErrorMessage, Form, Formik} from "formik";
import Input from "../atoms/inputs/Input";
import {Redirect} from "react-router-dom";
import Button from "../atoms/buttons/Button";
import {authenticate, getToken} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import {ACTION_TYPES} from "../../actions/actionTypes";
import {Icon} from "../atoms/logotypes/Icon";

const AuthFormContainer = styled.div`
  display: flex;
  width: 40rem;
  height: 41rem;
  background-color: rgba(0, 0, 0,  .85);
  color: white;
  border-radius: 4%;
  position: relative;
  align-items: center;
  flex-direction: column;
`;

const IconContainer = styled(Icon)`
   position: absolute;
   top: -4rem;
   left: calc(50% - 4rem);
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const StyledInput = styled(Input)`
  margin-top: 3rem;
  
  :first-of-type {
    margin-top: 5rem;
  }
`;

const SubmitWrapper = styled.div`
  margin-top: 3rem;
`;

const StyledErrorMsg = styled.div`
  margin: 1rem 0 0;
  font-weight: ${({theme}) => theme.medium};
  color: red;
  text-align: center;
  font-size:${({theme}) => theme.fontSize.s};
  display: flex;
`;

const AuthForm = () => {

    const dispatch = useDispatch();
    const token = getToken();
    const isError = useSelector(state => state.authError);
    const navigationItemController = useSelector(state => state.navigationItemController);
    const loginButtonDisabled = useSelector(state => state.loginButtonDisabled);


        return (
        <>
            <AuthFormContainer>
                <IconContainer/>
                <LoginParagraph margintop='8rem'>
                    Zaloguj się, aby zarządzać aplikacją.
                </LoginParagraph>
                <Formik
                    initialValues={{
                        username: '',
                        password: '',
                    }}
                    validate={values => {
                        const errors = {};

                        if (!values.username) {
                            errors.username = 'Login jest wymagany!';
                        }

                        if (!values.password) {
                            errors.password = 'Hasło jest wymagane!';
                        }

                        return errors;
                    }}
                    onSubmit={values => {
                        dispatch({type: ACTION_TYPES.AUTHENTICATE_REQUEST});
                        dispatch(authenticate(values.username, values.password))
                    }}
                >
                    {({values, handleChange, handleBlur}) => {
                        if (token && navigationItemController[0]) {
                            return <Redirect to={`navigation-item/${navigationItemController[0]._id}`}/>;
                        }
                        return (
                            <StyledForm>
                                <StyledInput
                                    name="username"
                                    type="text"
                                    placeholder="Login"
                                    onChange={handleChange}
                                    onFocus={() => dispatch({type: ACTION_TYPES.AUTHENTICATE_ON_FOCUS})}
                                    onBlur={handleBlur}
                                    value={values.username}
                                />
                                <ErrorMessage name="username" component={StyledErrorMsg}/>

                                <StyledInput
                                    name="password"
                                    type="password"
                                    placeholder="Hasło"
                                    onChange={handleChange}
                                    onFocus={() => dispatch({type: ACTION_TYPES.AUTHENTICATE_ON_FOCUS})}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />
                                <ErrorMessage name="password" component={StyledErrorMsg}/>
                                {isError && <StyledErrorMsg>Podano nieprawidłowy login lub hasło.</StyledErrorMsg>}
                                <SubmitWrapper>
                                    {loginButtonDisabled || token? //token checked also because of redirect lag
                                        <Button isDisabled={true} Login={true} type="submit">Zaloguj</Button>
                                        :
                                        <Button Login={true} type="submit">Zaloguj</Button>
                                    }
                                </SubmitWrapper>
                            </StyledForm>
                        );
                    }}
                </Formik>
            </AuthFormContainer>
        </>
    )
};


export default AuthForm;

