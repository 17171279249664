import styled from "styled-components";

const PortalBackground = styled.div`
    background-color: rgba(13,12,11,0.8);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default PortalBackground