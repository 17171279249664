import styled, {css} from "styled-components";
import React from "react";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const MenuItemWrapper = styled.li`
   width: 100%;
   min-height: 3rem;
   display: flex;
   margin-bottom: 1rem;
   text-decoration: none;
   list-style: none;
   position: relative;
   align-items: center;
   color: white;
   
   ${({active}) =>
    active &&
    css`
          color: ${({theme}) => theme.app_orange};
   `}
`;

const TextWrapper = styled.div`
  display: flex;
  cursor: pointer;
  font-family: 'Anton', sans-serif;
  letter-spacing: 0.2px;
  font-size: ${({theme}) => theme.fontSize.xl}
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
`;


const MenuItemActive = styled.div`
   width: 2.3rem;
   height: 0.3rem;
   display: flex;
   background-color: ${({theme}) => theme.app_orange};
   margin-bottom: 1rem;
   position: absolute;
   left: -3.5rem;
`;

const MenuItem = React.memo(({item}) => {

    let active = false;
    const canUserChangePath = useSelector(state => !state.isModuleEditing);

    let {navItemId} = useParams();
    if (navItemId === item._id) active = true;

    return (
        active ?
            <MenuItemWrapper active>
                <MenuItemActive/>
                <TextWrapper>
                    {item.name.toUpperCase()}
                </TextWrapper>
            </MenuItemWrapper>
            :
            <>
                {canUserChangePath ?
                    <MenuItemWrapper as={Link} to={item._id}>
                        <TextWrapper>
                            {item.name.toUpperCase()}
                        </TextWrapper>
                    </MenuItemWrapper>
                    :
                    <>
                        <MenuItemWrapper onClick={() => alert('Nie można zmienić lokalizacji - modul w trakcie edycji.')}>
                            <TextWrapper>
                                {item.name.toUpperCase()}
                            </TextWrapper>
                        </MenuItemWrapper>
                    </>

                }
            </>

    )
});
export default MenuItem;