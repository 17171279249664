import React, {useRef, useState, useEffect} from "react";
import {INPUT_TYPES, MODULES} from "../../../utils/modules";
import ActionContainer from "../../molecules/ActionContainer";
import {ModuleWrapper} from "../../atoms/wrappers/ModuleWrapper";
import {HorizontalModuleWrapper} from "../../atoms/wrappers/HorizontalModuleWrapper";
import {ModuleContentWrapper} from "../../atoms/wrappers/ModuleContentWrapper";
import {ModuleHeading} from "../../atoms/headings/ModuleHeading";
import {useLocation} from "react-router-dom";
import {addItem, editItem} from "../../../actions";
import {useDispatch, useSelector} from "react-redux";
import {ACTION_TYPES} from "../../../actions/actionTypes";
import {
    checkIsModuleLoading,
    getFieldValue,
    getLastElement,
    getModuleId,
    isError,
    moduleEmptyFieldsValidation
} from "../../../utils/moduleFunctions";
import {useDrag, useDrop} from "react-dnd";
import _ from "lodash";
import {EDIT_MODULE} from "../../../actions/requestTypes";
import ModuleInputWrapper from "../../molecules/ModuleInputWrapper";
import {DnDWrapper} from "../../atoms/wrappers/DnDWrapper";
import ModuleSpinner from "../../molecules/ModuleSpinner";


const ShortMenuModule = ({module, index, id, removeModuleTrigger, disabled = true, moveCard, canModuleDrag,saveNavigationItemOrder}) => {


//  ############### DnD ###############

    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'MODULE',
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    });
    const [{isDragging}, drag] = useDrag({
        item: {type: 'MODULE', id, index},
        canDrag: canModuleDrag,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => saveNavigationItemOrder(),
    });
    drag(drop(ref));


    // --- INITIALIZATION ---
    let location = useLocation();
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(disabled);
    const [moduleEmptyFields, setModuleEmptyFields] = useState([]);

    // --- GET MODULE VALUES FROM REDUX ---
    const moduleEditableData = useSelector(state => state.moduleEditableData);

    (() => {
        if (!isDisabled && moduleEditableData) module = moduleEditableData;
    })();

    const currentNavItem = useSelector(state => {
            if (state.navigationItemController && state.navigationItemController.length)
                return getLastElement(state.navigationItemController);
        }
    );

    //
    // --- GET TYPE FROM ENUM ---
    const getModuleType = () => MODULES[module.type.toUpperCase()];

    //--- CHECK IF MODULE IS CREATED BY USER ---
    const moduleToAdd = useSelector(state => state.moduleToAdd);
    const isCreatedByUser = (moduleToAdd === getModuleType().API_NAME);

    // --- GET FIELD FROM MODULE ---
    const getField = (type, moduleToSearch) => ({
        ...moduleToSearch.fields.find(field => field.name === type),
        module_index: index,
    });

    //--- ADD MODULE TO REDUX IF IS CREATED BY USER ---
    useEffect(() => {
        if (isCreatedByUser) {
            const moduleToEdit = _.cloneDeep(module);
            dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: moduleToEdit});
            setIsDisabled(false);
            setModuleEmptyFields([]);
        }
    }, []);

    // --- FIELD VALUE CHANGE ---
    const handleValueChange = (value, fieldType) => {
        let newModule = {...moduleEditableData};
        getField(fieldType, newModule).fieldValues[0].value = value;
        dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: newModule});
    };

    // --- EDIT REJECTED ---
    const resetValuesToDefault = () => {
        if (isCreatedByUser) {
            dispatch({type: ACTION_TYPES.MODULE_CANCEL_ADDING});
        }
        else {
            if (currentNavItem.modules && currentNavItem.modules.length) {
                module = currentNavItem.modules[index];
            }
            dispatch({type: ACTION_TYPES.TOGGLE_MODULE_EDIT, payload: false});
        }
        setModuleEmptyFields([]);
        setIsDisabled(true)
    };

    // --- SAVE MODULE ---
    const saveModuleTrigger = () => {
        let emptyFields = moduleEmptyFieldsValidation(moduleEditableData);

        if (!emptyFields.length) {
            if (isCreatedByUser) {
                dispatch(addItem(getModuleId(location), moduleEditableData, currentNavItem.modules));
                resetValuesToDefault();
                dispatch({type: ACTION_TYPES.SAVE_CREATED_MODULE});
            } else {
                dispatch(editItem(getModuleId(location), EDIT_MODULE, moduleEditableData));
                resetValuesToDefault();
                dispatch({type: ACTION_TYPES.TOGGLE_MODULE_EDIT, payload: false});
                setIsDisabled(true)
            }
        } else setModuleEmptyFields(emptyFields)
    };

    // --- HANDLE LOADING SPINNER ---
    const isModuleLoading = useSelector(state => state.isModuleLoading);

    //  ############################################################


    const headerField = getField(MODULES.HEADER.API_NAME, module);


    // --- DATA VALIDATION ---
    const validateInput = (value, fieldToValidate) => {
        if (value.trim() === "") setModuleEmptyFields([...moduleEmptyFields, fieldToValidate]);
        else if (moduleEmptyFields.length) setModuleEmptyFields(moduleEmptyFields.filter(field => field.name !== fieldToValidate.name));
    };

    const opacity = isDragging ? 0 : 1;

    const getRef = () => isCreatedByUser ? null : ref;

    return (
        <DnDWrapper ref={getRef()} style={{opacity}}>
            <ModuleHeading canModuleDrag={canModuleDrag} isDisabled={isDisabled}>
                {getModuleType().UI_NAME}
            </ModuleHeading>
            <ModuleWrapper isSmall>
                {checkIsModuleLoading(module._id, isModuleLoading) && <ModuleSpinner/>}
                <ModuleContentWrapper style={isDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                    {!isCreatedByUser &&
                    <HorizontalModuleWrapper>
                        <ModuleInputWrapper isDisabled={isDisabled}
                                            fieldValue={getFieldValue(headerField)}
                                            handleChange={(value) => {
                                                handleValueChange(value, MODULES.HEADER.API_NAME);
                                                validateInput(value, headerField)
                                            }}
                                            inputType={INPUT_TYPES.HEADER}
                                            saveModuleTrigger={saveModuleTrigger}
                                            error={isError(headerField, moduleEmptyFields)}
                        />
                    </HorizontalModuleWrapper>
                    }
                    <HorizontalModuleWrapper>
                        <p>Moduł wyświetla menu będące skrótem do zawartości obecnego widoku.</p>
                    </HorizontalModuleWrapper>
                </ModuleContentWrapper>
                <ActionContainer isDisabled={isDisabled} setIsDisabled={setIsDisabled}
                                 removeModuleTrigger={removeModuleTrigger} idToRemove={module._id}
                                 resetValuesToDefault={resetValuesToDefault}
                                 saveModuleTrigger={saveModuleTrigger}
                                 module={module}
                />
            </ModuleWrapper>
        </DnDWrapper>
    )
};
export default ShortMenuModule;