import React, {useEffect, useState,useRef} from "react";
import ModuleInputWrapper from "../../molecules/ModuleInputWrapper";
import {INPUT_TYPES, MODULES, PLACEHOLDERS} from "../../../utils/modules";
import Button from "../../atoms/buttons/Button";
import ActionContainer from "../../molecules/ActionContainer";
import {ModuleWrapper} from "../../atoms/wrappers/ModuleWrapper";
import {HorizontalModuleWrapper} from "../../atoms/wrappers/HorizontalModuleWrapper";
import {ModuleContentWrapper} from "../../atoms/wrappers/ModuleContentWrapper";
import {ModuleHeading} from "../../atoms/headings/ModuleHeading";
import {useDispatch, useSelector} from "react-redux";
import {addItem, editItem} from "../../../actions";
import {EDIT_MODULE} from "../../../actions/requestTypes";
import {useLocation} from "react-router-dom";
import {ACTION_TYPES} from "../../../actions/actionTypes";
import {
    checkIsModuleLoading,
    getFieldValue,
    getLastElement,
    getModuleId,
    isError, moduleEmptyFieldsValidation
} from "../../../utils/moduleFunctions";
import _ from "lodash";
import ModuleSpinner from "../../molecules/ModuleSpinner";
import {useDrag, useDrop} from "react-dnd";
import {DnDWrapper} from "../../atoms/wrappers/DnDWrapper";


const VideoModule = ({module, index, id,removeModuleTrigger, disabled = true, manageFileTrigger,moveCard,canModuleDrag,saveNavigationItemOrder}) => {

    //  ############### DnD ###############
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'MODULE',
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) return;
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex
        },
    });
    const [{isDragging}, drag] = useDrag({
        item: {type: 'MODULE', id, index},
        canDrag: canModuleDrag,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => saveNavigationItemOrder(),
    });
    drag(drop(ref));

//  ############### GENERAL MODULE HANDLER ###############

    // --- INITIALIZATION ---
    let location = useLocation();
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(disabled);
    const [moduleEmptyFields, setModuleEmptyFields] = useState([]);

    // --- GET MODULE VALUES FROM REDUX ---
    const moduleEditableData = useSelector(state => state.moduleEditableData);
    const currentNavItem = useSelector(state => {
            if (state.navigationItemController && state.navigationItemController.length)
                return getLastElement(state.navigationItemController);
        }
    );

    (() => {
        if (!isDisabled && moduleEditableData) module = moduleEditableData;
    })();

    // --- GET TYPE FROM ENUM ---
    const getModuleType = () => MODULES[module.type.toUpperCase()];

    //--- CHECK IF MODULE IS CREATED BY USER ---
    const moduleToAdd = useSelector(state => state.moduleToAdd);
    const isCreatedByUser = (moduleToAdd === getModuleType().API_NAME);

    // --- GET FIELD FROM MODULE ---
    const getField = (type, moduleToSearch) => ({
        ...moduleToSearch.fields.find(field => field.name === type),
        module_index: index,
    });

    //--- ADD MODULE TO REDUX IF IS CREATED BY USER ---
    useEffect(() => {
        if (isCreatedByUser) {
            const moduleToEdit = _.cloneDeep(module);
            dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: moduleToEdit});
            setIsDisabled(false);
            setModuleEmptyFields([]);
        }
    }, []);

    // --- FIELD VALUE CHANGE ---
    const handleValueChange = (value, fieldType) => {
        let newModule = {...moduleEditableData};
        getField(fieldType, newModule).fieldValues[0].value = value;
        dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: newModule});
    };

    // --- EDIT REJECTED ---
    const resetValuesToDefault = () => {
        if (isCreatedByUser) {
            dispatch({type: ACTION_TYPES.MODULE_CANCEL_ADDING});
        }
        else {
            if (currentNavItem.modules && currentNavItem.modules.length) {
                module = currentNavItem.modules[index];
            }
            dispatch({type: ACTION_TYPES.TOGGLE_MODULE_EDIT, payload: false});
        }
        setModuleEmptyFields([]);
        setIsDisabled(true)
    };

    // --- SAVE MODULE ---
    const saveModuleTrigger = () => {
        let emptyFields = moduleEmptyFieldsValidation(moduleEditableData);

        if (!emptyFields.length) {
            if (isCreatedByUser) {
                dispatch(addItem(getModuleId(location), moduleEditableData, currentNavItem.modules));
                resetValuesToDefault();
                dispatch({type: ACTION_TYPES.SAVE_CREATED_MODULE});
            } else {
                dispatch(editItem(getModuleId(location), EDIT_MODULE, moduleEditableData));
                resetValuesToDefault();
                dispatch({type: ACTION_TYPES.TOGGLE_MODULE_EDIT, payload: false});
                setIsDisabled(true)
            }
        } else setModuleEmptyFields(emptyFields)
    };

    // --- HANDLE LOADING SPINNER ---
    const isModuleLoading = useSelector(state => state.isModuleLoading);

//  ############################################################

    // --- FIELDS ---
    const headerField = getField(MODULES.HEADER.API_NAME, module);
    const fileField = getField(MODULES.LINK.API_NAME, module);

    // --- DATA VALIDATION ---
    const validateInput = (value, fieldToValidate) => {
        if (value.trim() === "") setModuleEmptyFields([...moduleEmptyFields, fieldToValidate]);
        else if (moduleEmptyFields.length) setModuleEmptyFields(moduleEmptyFields.filter(field => field.name !== fieldToValidate.name));

    };

    // --- POPUP FIELD VALIDATION ---
    useEffect(() => {
        if (moduleEmptyFields.length) validateInput(getFieldValue(fileField), fileField)
    }, [getFieldValue(fileField)]);

    const opacity = isDragging ? 0 : 1;
    const getRef = () => isCreatedByUser? null : ref;

    return (
        <DnDWrapper ref={getRef()} style={{opacity}}>
            <ModuleHeading canModuleDrag={canModuleDrag} isDisabled={isDisabled} isLoading={checkIsModuleLoading(module._id,isModuleLoading)}>
            {getModuleType().UI_NAME}
            </ModuleHeading>
            <ModuleWrapper isSmall>
                {checkIsModuleLoading(module._id,isModuleLoading) && <ModuleSpinner/>}
                <ModuleContentWrapper style={isDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                    <HorizontalModuleWrapper>
                        <ModuleInputWrapper marginRight={'3rem'}
                                            isDisabled={isDisabled}
                                            fieldValue={getFieldValue(headerField)}
                                            handleChange={(value) => {
                                                handleValueChange(value, MODULES.HEADER.API_NAME);
                                                validateInput(value, headerField)
                                            }}
                                            inputType={INPUT_TYPES.TITLE}
                                            saveModuleTrigger={saveModuleTrigger}
                                            error={isError(headerField,moduleEmptyFields)}
                        />
                        <ModuleInputWrapper marginRight={'3rem'}
                                            isDisabled={true}
                                            fieldValue={getFieldValue(fileField)}
                                            inputType={INPUT_TYPES.FILE}
                                            saveModuleTrigger={saveModuleTrigger}
                                            error={isError(fileField,moduleEmptyFields)}
                                            placeholder={PLACEHOLDERS.NO_FILE_SPECIFIED}
                        />
                        <Button Smaller={true} onClick={(e) => {
                            e.preventDefault();
                            manageFileTrigger(fileField);
                        }}>Dodaj wideo</Button>
                    </HorizontalModuleWrapper>
                </ModuleContentWrapper>
                <ActionContainer isDisabled={isDisabled} setIsDisabled={setIsDisabled}
                                 removeModuleTrigger={removeModuleTrigger} idToRemove={module._id}
                                 resetValuesToDefault={resetValuesToDefault}
                                 saveModuleTrigger={saveModuleTrigger}
                                 module={module}
                />
            </ModuleWrapper>
        </DnDWrapper>
    )
};
export default VideoModule;