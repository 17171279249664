import axios from 'axios';
import {ACTION_TYPES} from '../actions/actionTypes'

// --- API ---
export const API_VERSION = process.env.REACT_APP_WWWK_API_VERSION;
export const API_URL = process.env.REACT_APP_WWWK_API_URL;
export const API_PATH = process.env.REACT_APP_WWWK_API_PATH;

export const API = `${API_URL}/${API_PATH}/${API_VERSION}`;

// --- TOKEN ---
export const getHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem('AUTH')}`,
});

export const getToken = () => (localStorage.getItem('AUTH') || null);

export const logInUser = token => {
    localStorage.setItem('AUTH', token);
};

const errorHandler = ({response}) => dispatch => {
    console.log(response);

    if (response) {
        if (response.status === 401 || response.status === 403) {
            dispatch({
                type: ACTION_TYPES.SET_API_ERROR,
                payload: {
                    code: response.status,
                    message: response.data ? response.data.message : response.statusText
                }
            });
        }
    }
};

// --- AUTH ---
export const authenticate = (username, password) => dispatch => {
    dispatch({type: ACTION_TYPES.AUTHENTICATE_REQUEST});
    return axios
        .post(`${API}/auth`, {
            login: username,
            password,
        })
        .then(payload => {
            dispatch(getNavigationItemList(payload.data.access_token));
            return payload;
        })
        .then(async (payload) => {
            await logInUser(payload.data.access_token);
            return payload
        })
        .then((payload) => {
            dispatch({type: ACTION_TYPES.AUTHENTICATE_SUCCESS, payload: payload.data})
        })
        .catch(err => {
            dispatch({type: ACTION_TYPES.AUTHENTICATE_FAILURE, payload: err});
        });
};

// --- MENU ---
export const getNavigationItemList = (token) => dispatch => {
    dispatch({type: ACTION_TYPES.FETCH_REQUEST});

    return axios
        .get(`${API}/navigationItem?where={"parent": {"$exists": false, "$eq": null}}`, {
            headers: {Authorization: `Bearer ${token}`},
        })
        .then(response => {
            dispatch({type: ACTION_TYPES.MENU_FETCH_SUCCESS, payload: response.data.data});
        })
        .catch(err => dispatch(errorHandler(err)));
};

// --- FETCH  DATA ---
export const fetchModules = (navigationItemId, showSpinner = true) => dispatch => {
    if (showSpinner) dispatch({type: ACTION_TYPES.FETCH_REQUEST});

    if (navigationItemId) {
        return axios
            .get(`${API}/navigationItem/${navigationItemId}`, {
                headers: getHeaders()
            })
            .then(({data}) => {
                dispatch({
                    type: ACTION_TYPES.FETCH_MODULES_SUCCESS,
                    payload: data.data,
                });
            })
            .catch(err => dispatch(errorHandler(err)));
    }
};


// --- EDIT DATA ---
export const editItem = (navigationItemId, requestType, data, editBecauseNavItemAdded = false) => dispatch => {

    dispatch({type: ACTION_TYPES.EDIT_MODULE_REQUEST, payload: data._id});
    return axios
        .put(`${API}/${requestType.path}/${data._id}`,
            {
                module: data
            },
            {
                headers: getHeaders(),
            })
        .then(() => {
            if (!editBecauseNavItemAdded) {
                // dispatch(fetchModules(navigationItemId));
                return axios
                    .get(`${API}/navigationItem/${navigationItemId}`, {
                        headers: getHeaders()
                    })
                    .then(({data}) => {
                        dispatch({
                            type: ACTION_TYPES.FETCH_MODULES_SUCCESS,
                            payload: data.data,
                        });
                        dispatch({type: ACTION_TYPES.EDIT_MODULE_RESPONSE});
                    })
                    .catch(err => dispatch(errorHandler(err)));
            }
            else dispatch({type: ACTION_TYPES.EDIT_MODULE_RESPONSE});

        })
        .catch(err => {
            dispatch({type: ACTION_TYPES.EDIT_MODULE_RESPONSE});
            dispatch(errorHandler(err))
        });
};

// --- REMOVE DATA ---
export const removeItem = (navigationItemId, itemToRemoveId) => dispatch => {
    dispatch({type: ACTION_TYPES.FETCH_REQUEST});
    return axios
        .delete(`${API}/navigationItem/module/${itemToRemoveId}`,
            {
                headers: getHeaders(),
            })
        .then(() => {
            dispatch(fetchModules(navigationItemId));
        })
        .catch(err => dispatch(errorHandler(err)));
};

//-- ADD ITEM ---
export const addItem = (navigationItemId, newModule, allModules) => dispatch => {

    let maxOrder = allModules.length ? allModules[0].order : -1;

    allModules.map(el => {
        if (el.order > maxOrder) maxOrder = el.order
    });

    dispatch({type: ACTION_TYPES.FETCH_REQUEST});
    return axios
        .post(`${API}/navigationItem/${navigationItemId}/module`,
            {
                module: {
                    ...newModule,
                    order: maxOrder + 1
                }
            },
            {
                headers: getHeaders(),
            })
        .then(() => {
            dispatch(fetchModules(navigationItemId));
        })
        .catch(err => dispatch(errorHandler(err)));
};


// --- UPLOAD ITEM ---
export const uploadFile = (file) => dispatch => {

    // dispatch({type: ACTION_TYPES.FETCH_REQUEST});
    return axios
        .post(`${API}/file/upload`,
            file,
            {
                headers: getHeaders(),
            },
        )
        .then(response => {
            return response.data.fileUrl;
        })
        .catch(err => dispatch(errorHandler(err)));
};


// --- UPLOAD ITEM ---
export const getFiles = () => dispatch => {

    return axios
        .get(`${API}/file/getFileList`, {

                headers: getHeaders(),
            },
        )
        .then(response => {
            dispatch({type: ACTION_TYPES.IMPORT_FILES, payload: {data: response.data}});
        })
        .catch(err => dispatch(errorHandler(err)));
};

// --- GET ALL NAVIGATION ITEMS == LINKS ---
export const getAllNavigationItemList = () => dispatch => {

    return axios
        .get(`${API}/navigationItem`, {
            headers: getHeaders(),
        })
        .then(response => {
            dispatch({type: ACTION_TYPES.SET_ALL_NAVIGATION_ITEMS, payload: response.data.data});
        })
        .catch(err => dispatch(errorHandler(err)));
};


export const addNavigationItem = (data) => dispatch => {

    return axios
        .post(`${API}/navigationItem`,
            data,
            {
                headers: getHeaders(),
            })
        .then(response => {

            dispatch({type: ACTION_TYPES.SET_NEW_NAVIGATION_ITEM, payload: response.data.data});
        })
        .catch(err => dispatch(errorHandler(err)));
};

export const removeNavigationItem = (id) => dispatch => {

    return axios
        .delete(`${API}/navigationItem/${id}`,
            {
                headers: getHeaders(),
            })
        .then(() => {
            dispatch(getNavigationItemList(getToken()))
        })
        .catch(err => dispatch(errorHandler(err)));
};


//--- NAME & TYPE EDIT ---
export const updateNavigationItem = (navigationItem) => dispatch => {

    return axios
        .put(`${API}/navigationItem/${navigationItem._id}`,
            {
                ...navigationItem
            },
            {
                headers: getHeaders(),
            })
        .then((response) => {
            dispatch({type: ACTION_TYPES.REDIRECT_STACK_CURRENT_EDIT, payload: navigationItem});
        })
        .then(() => {
            return axios
                .get(`${API}/navigationItem?where={"parent": {"$exists": false, "$eq": null}}`, {
                    headers: getHeaders(),
                })
                .then(response => {
                    dispatch({type: ACTION_TYPES.MENU_FETCH_AFTER_EDIT, payload: response.data.data});
                })
                .then(() => {
                    dispatch(getAllNavigationItemList());
                })
                .catch(err => dispatch(errorHandler(err)));
        })
        .catch(err => dispatch(errorHandler(err)));
};


//--- REORDER ---
export const updateNavigationItemModulesOrder = (navigationItem, modules) => dispatch => {

    return axios
        .put(`${API}/navigationItem/${navigationItem._id}`,
            {
                ...navigationItem,
                modules,
            },
            {
                headers: getHeaders(),
            })
        .then(() =>
            dispatch(fetchModules(navigationItem._id, false))
        )
        .catch(err => dispatch(errorHandler(err)));
};