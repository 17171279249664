export const FETCH_MODULES = {
    type: "GET_LIST",
    path: 'navigationItem',
    itemType: 'navigationItem',
};

export const EDIT_MODULE = {
    path: 'navigationItem/module',
};

export const ADD_MODULE = {
    path_prefix: 'navigationItem',
    path_postfix: 'module',
};