import styled, {keyframes} from 'styled-components'
import React from 'react'

const SpinnerOverlay = styled.div`
  height: auto;
  min-height: 60vh;
  width: 100%;
  justify-content: center;
  justify-items: center;
  align-items: center;
  display: flex;
`;

export const spinnerKeyFrames = keyframes`
  0% {-webkit-transform: rotate(0deg);}
    50% {-webkit-transform: rotate(180deg);}
    100% {-webkit-transform: rotate(360deg);}
`;

export const SpinnerContainer = styled.div`
  width: 6rem;
  height: 6rem;
  border: 3px solid rgba(195, 195, 195,0.6);
  border-radius: 50%;
  border-top-color: #636767;
  animation-name: ${spinnerKeyFrames};
  -webkit-animation-name: ${spinnerKeyFrames};
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
`;

const WithSpinner = WrappedComponent => ({isLoading, ...restProps}) => {

    return isLoading ? (
        <SpinnerOverlay>
            <SpinnerContainer/>
        </SpinnerOverlay>
    ) : <WrappedComponent {...restProps}/>
};

export default WithSpinner;

