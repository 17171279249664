import ModuleInput from "../atoms/inputs/ModuleInput";
import styled, {css} from 'styled-components';
import React from "react";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: ${({marginRight}) => marginRight || '0'};
  position: relative;
`;

export const InputDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  font-size: ${({theme}) => theme.fontSize.s};
  margin-bottom: 0.5rem;
  
  ${({error}) =>
    error &&
    css`
        color: ${({ theme }) => theme.app_orange};
   `}
   
  ${({isTypeValid}) =>
    isTypeValid === false &&
    css`
        color: ${({ theme }) => theme.app_orange};
   `}
`;

export const ErrorDesription = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  font-size: 1rem;
  color: ${({ theme }) => theme.app_orange};
  position:absolute;
  bottom: -1.7rem;
`;

const ModuleInputWrapper = ({inputType, width, fieldValue, handleChange, saveModuleTrigger, isDisabled, type = 'text',marginRight,error,placeholder}) => {

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
            saveModuleTrigger();
        }
    };

    return (
        <Wrapper inputType={inputType} marginRight={marginRight}>
            <InputDescription error={error}>{inputType}</InputDescription>
            <ModuleInput type={type}
                         disabled={isDisabled}
                         value={fieldValue}
                         onKeyDown={handleKeyDown}
                         onChange={(e) => handleChange(e.target.value)}
                         width={width}
                         inputType={inputType}
                         error={error}
                         placeholder={placeholder}
                         min={1}
            />
            {error && <ErrorDesription>Pole nie może być puste.</ErrorDesription>}
        </Wrapper>
    )
};
export default ModuleInputWrapper;