import React, {useEffect, useRef, useState} from "react";
import {INPUT_TYPES, MODULES, PLACEHOLDERS} from "../../../utils/modules";
import ActionContainer from "../../molecules/ActionContainer";
import {ModuleWrapper} from "../../atoms/wrappers/ModuleWrapper";
import {HorizontalModuleWrapper} from "../../atoms/wrappers/HorizontalModuleWrapper";
import {ModuleContentWrapper} from "../../atoms/wrappers/ModuleContentWrapper";
import {ModuleHeading} from "../../atoms/headings/ModuleHeading";
import {useDispatch, useSelector} from "react-redux";
import {addItem, editItem} from "../../../actions";
import {useLocation} from "react-router-dom";
import ModuleInputWrapper from "../../molecules/ModuleInputWrapper";
import {ACTION_TYPES} from "../../../actions/actionTypes";
import {EDIT_MODULE} from "../../../actions/requestTypes";
import _ from "lodash";
import {
    checkIsModuleLoading,
    getFlatValue,
    getLastElement,
    getModuleId,
    isError,
    moduleEmptyFieldsValidation,
    splitValues
} from "../../../utils/moduleFunctions";
import Button from "../../atoms/buttons/Button";
import ModuleSpinner from "../../molecules/ModuleSpinner";
import {useDrag, useDrop} from "react-dnd";
import {DnDWrapper} from "../../atoms/wrappers/DnDWrapper";


const PoiModule = ({module, index, id,removeModuleTrigger, disabled = true, manageFileTrigger,moveCard,canModuleDrag,saveNavigationItemOrder}) => {


    //  ############### DnD ###############
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'MODULE',
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) return;
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex
        },
    });
    const [{isDragging}, drag] = useDrag({
        item: {type: 'MODULE', id, index},
        canDrag: canModuleDrag,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => saveNavigationItemOrder(),
    });
    drag(drop(ref));

    // --- INITIALIZATION ---
    let location = useLocation();
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(disabled);
    const [moduleEmptyFields, setModuleEmptyFields] = useState([]);
    const [isLatitudeValid, setIsLatitudeValid] = useState(true);
    const [isLongitudeValid, setIsLongitudeValid] = useState(true);
    const [empty3DModelValues, setEmpty3DModelValues] = useState([]);


    // --- GET MODULE VALUES FROM REDUX ---
    const moduleEditableData = useSelector(state => state.moduleEditableData);
    const currentNavItem = useSelector(state => {
            if (state.navigationItemController && state.navigationItemController.length)
                return getLastElement(state.navigationItemController);
        }
    );


    (() => {
        if (!isDisabled && moduleEditableData) module = moduleEditableData;
    })();

    // --- GET TYPE FROM ENUM ---
    const getModuleType = () => MODULES[module.type.toUpperCase()];

    //--- CHECK IF MODULE IS CREATED BY USER ---
    const moduleToAdd = useSelector(state => state.moduleToAdd);
    const isCreatedByUser = (moduleToAdd === getModuleType().API_NAME);

    // --- GET FIELD FROM MODULE ---
    const getField = (type, moduleToSearch) => ({
        ...moduleToSearch.fields.find(field => field.name === type),
        module_index: index,
    });


    //--- ADD MODULE TO REDUX IF IS CREATED BY USER ---
    useEffect(() => {
        if (isCreatedByUser) {
            const moduleToEdit = _.cloneDeep(module);
            dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: moduleToEdit});
            setIsDisabled(false);
            setModuleEmptyFields([]);
            setIsLatitudeValid(true);
            setIsLongitudeValid(true);
        }
    }, []);

    // --- FIELD VALUE CHANGE ---
    const handleValueChange = (value, fieldType) => {
        let newModule = {...moduleEditableData};
        if (fieldType === MODULES.MODEL_NOTIFICATION_DISTANCE.API_NAME) value = Math.abs(value);
        getField(fieldType, newModule).fieldValues[0].value = value;
        dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: newModule});
    };


    // --- LOCATION FIELD ---
    const locationField = getField(MODULES.LOCATION.API_NAME, module);

    const handleLocationChange = (newValue, index, type) => {
        let newLocationValue = getFieldValue(locationField);
        let newLocationValueSplit = splitValues(newLocationValue);

        if (type === INPUT_TYPES.LATITUDE) newLocationValueSplit.latitude = newValue;
        else newLocationValueSplit.longitude = newValue;

        newLocationValue = getFlatValue(newLocationValueSplit);
        handleValueChange(newLocationValue, MODULES.LOCATION.API_NAME);
    };

    // --- MODEL NAME FIELD ---
    const modelNameField = getField(MODULES.MODEL_NAME.API_NAME, module);

    // --- NOTIFICATION DISTANCE FIELD ---
    const notificationDistanceField = getField(MODULES.MODEL_NOTIFICATION_DISTANCE.API_NAME, module);

    // --- NOTIFICATION TITLE FIELD ---
    const notificationTitleField = getField(MODULES.MODEL_NOTIFICATION_TITLE.API_NAME, module);

    // --- NOTIFICATION TEXT FIELD ---
    const notificationTextField = getField(MODULES.MODEL_NOTIFICATION_TEXT.API_NAME, module);

    // --- NOTIFICATION IMAGE FIELD ---
    const notificationImageField = getField(MODULES.MODEL_NOTIFICATION_IMAGE.API_NAME, module);

    // --- 3D MODEL FIELD ---
    const model3DField = getField(MODULES.MODEL_3D.API_NAME, module);


    const resetValuesToDefault = () => {

        if (isCreatedByUser) {
            dispatch({type: ACTION_TYPES.MODULE_CANCEL_ADDING});
        }
        else {
            if (currentNavItem.modules && currentNavItem.modules.length) {
                module = currentNavItem.modules[index];
            }
            dispatch({type: ACTION_TYPES.TOGGLE_MODULE_EDIT, payload: false});
        }
        setModuleEmptyFields([]);
        setIsLatitudeValid(true);
        setIsLongitudeValid(true);
        setIsDisabled(true);
    };


    // --- SAVE MODULE ---
    const saveModuleTrigger = () => {

        let emptyFields = moduleEmptyFieldsValidation(moduleEditableData);
        if (checkIsModelEmpty() && checkIsLocationEmpty() && !emptyFields.length) {
            if (isCreatedByUser) {
                dispatch(addItem(getModuleId(location), moduleEditableData, currentNavItem.modules));
                resetValuesToDefault();
                dispatch({type: ACTION_TYPES.SAVE_CREATED_MODULE});
            } else {
                dispatch(editItem(getModuleId(location), EDIT_MODULE, moduleEditableData));
                resetValuesToDefault();
                dispatch({type: ACTION_TYPES.TOGGLE_MODULE_EDIT, payload: false});
                setIsDisabled(true)
            }
        } else setModuleEmptyFields(emptyFields)
    };

    const getFieldValue = (field) => {
        if (field && field.fieldValues) return field.fieldValues[0].value;
        else return '';
    };
    const get3DFileType = (index) => {
        switch (index) {
            case 0:
                return MODULES.MODEL_3D.GLTF;
            case 1:
                return MODULES.MODEL_3D.BIN;
            case 2:
                return MODULES.MODEL_3D.PNG;
            default:
                return null;
        }
    };

    // --- HANDLE LOADING SPINNER ---
    const isModuleLoading = useSelector(state => state.isModuleLoading);


    // --- LOCATION VALIDATION ---
    const validateLocation = (value, type) => {
        if (type === INPUT_TYPES.LATITUDE) {
            if (value.trim() === "") {
                if (isLatitudeValid) setIsLatitudeValid(false)
            } else if (!isLatitudeValid) setIsLatitudeValid(true);
        } else {

            if (value.trim() === "") {
                if (isLongitudeValid) setIsLongitudeValid(false)
            } else if (!isLongitudeValid) setIsLongitudeValid(true);
        }
    };


    const checkIsLocationEmpty = () => {
        let isValid = true;

        if (splitValues(getFieldValue(locationField)).latitude.trim() === "") {
            isValid = false;
            setIsLatitudeValid(false);
        }
        if (splitValues(getFieldValue(locationField)).longitude.trim() === "") {
            isValid = false;
            setIsLongitudeValid(false);
        }
        return isValid;
    };

    const checkIsModelEmpty = () => {
        let isModelValid = true;
        let emptyModelValues = [];

        model3DField.fieldValues.length && model3DField.fieldValues.map((item, index) => {
            if (item.value.trim() === ""){
                emptyModelValues.push(index);
                isModelValid = false;
            }
        });
        setEmpty3DModelValues(emptyModelValues);
        return isModelValid
    };

    // --- DATA VALIDATION ---
    const validateInput = (value, fieldToValidate) => {
        if (value.trim() === "") setModuleEmptyFields([...moduleEmptyFields, fieldToValidate]);
        else if (moduleEmptyFields.length) setModuleEmptyFields(moduleEmptyFields.filter(field => field.name !== fieldToValidate.name))
    };

    // --- FIELD VALUE VALIDATION ---
    const isValueEmptyError = (index, emptyValues) => {
        if (emptyValues.length)
            return emptyValues.some(value => value === index);
        else return undefined;
    };

    // --- POPUP FIELD VALIDATION ---
    useEffect(() => {
        if (moduleEmptyFields.length) validateInput(getFieldValue(notificationImageField), notificationImageField)
    }, [getFieldValue(notificationImageField)]);

    // --- POPUP FIELD VALIDATION ---
    useEffect(() => {
        if (moduleEmptyFields.length) checkIsModelEmpty()
    }, [model3DField.fieldValues[0].value,model3DField.fieldValues[1].value,model3DField.fieldValues[2].value]);

    const opacity = isDragging ? 0 : 1;
    const getRef = () => isCreatedByUser? null : ref;

    return (
        <DnDWrapper ref={getRef()} style={{opacity}}>
            <ModuleHeading canModuleDrag={canModuleDrag} isDisabled={isDisabled} isLoading={checkIsModuleLoading(module._id, isModuleLoading)}>
                {getModuleType().UI_NAME}
            </ModuleHeading>
            <ModuleWrapper>
                {checkIsModuleLoading(module._id, isModuleLoading) && <ModuleSpinner/>}
                <ModuleContentWrapper style={isDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                    <HorizontalModuleWrapper>
                        <ModuleInputWrapper marginRight={'3rem'}
                                            isDisabled={isDisabled}
                                            fieldValue={splitValues(getFieldValue(locationField)).latitude}
                                            handleChange={(newValue) => {
                                                handleLocationChange(newValue, index, INPUT_TYPES.LATITUDE);
                                                validateLocation(newValue, INPUT_TYPES.LATITUDE);
                                            }}
                                            inputType={INPUT_TYPES.LATITUDE}
                                            type={'number'}
                                            saveModuleTrigger={saveModuleTrigger}
                                            error={!isLatitudeValid}
                        />
                        <ModuleInputWrapper isDisabled={isDisabled}
                                            fieldValue={splitValues(getFieldValue(locationField)).longitude}
                                            handleChange={(newValue) => {
                                                handleLocationChange(newValue, index, INPUT_TYPES.LONGITUDE);
                                                validateLocation(newValue, INPUT_TYPES.LONGITUDE);
                                            }}
                                            inputType={INPUT_TYPES.LONGITUDE}
                                            type={'number'}
                                            saveModuleTrigger={saveModuleTrigger}
                                            error={!isLongitudeValid}
                        />
                    </HorizontalModuleWrapper>
                    <HorizontalModuleWrapper>
                        <ModuleInputWrapper isDisabled={isDisabled}
                                            fieldValue={getFieldValue(modelNameField)}
                                            handleChange={(value) => {
                                                handleValueChange(value, MODULES.MODEL_NAME.API_NAME);
                                                validateInput(value, modelNameField);
                                            }}
                                            inputType={MODULES.MODEL_NAME.UI_NAME}
                                            saveModuleTrigger={saveModuleTrigger}
                                            error={isError(modelNameField, moduleEmptyFields)}
                        />
                    </HorizontalModuleWrapper>
                    {model3DField.fieldValues.map((item, index) =>
                        <HorizontalModuleWrapper key={index}>
                            <ModuleInputWrapper marginRight={'3rem'}
                                                isDisabled={true}
                                                fieldValue={model3DField.fieldValues[index].value}
                                                inputType={get3DFileType(index)}
                                                saveModuleTrigger={saveModuleTrigger}
                                                error={isValueEmptyError(index, empty3DModelValues)}
                                                placeholder={PLACEHOLDERS.NO_FILE_SPECIFIED}
                            />
                            <Button Smaller={true} onClick={(e) => {
                                e.preventDefault();
                                manageFileTrigger(model3DField, index);
                            }}>Dodaj plik</Button>
                        </HorizontalModuleWrapper>)}
                    <HorizontalModuleWrapper>
                        <ModuleInputWrapper type={'number'}
                                            isDisabled={isDisabled}
                                            fieldValue={getFieldValue(notificationDistanceField)}
                                            handleChange={(value) => {
                                                handleValueChange(value, MODULES.MODEL_NOTIFICATION_DISTANCE.API_NAME);
                                                validateInput(value, notificationDistanceField);
                                            }}
                                            inputType={MODULES.MODEL_NOTIFICATION_DISTANCE.UI_NAME}
                                            saveModuleTrigger={saveModuleTrigger}
                                            error={isError(notificationDistanceField, moduleEmptyFields)}
                        />
                    </HorizontalModuleWrapper>
                    <HorizontalModuleWrapper>
                        <ModuleInputWrapper isDisabled={isDisabled}
                                            fieldValue={getFieldValue(notificationTitleField)}
                                            handleChange={(value) => {
                                                handleValueChange(value, MODULES.MODEL_NOTIFICATION_TITLE.API_NAME);
                                                validateInput(value, notificationTitleField);
                                            }}
                                            inputType={MODULES.MODEL_NOTIFICATION_TITLE.UI_NAME}
                                            saveModuleTrigger={saveModuleTrigger}
                                            error={isError(notificationTitleField, moduleEmptyFields)}
                        />
                    </HorizontalModuleWrapper>
                    <HorizontalModuleWrapper>
                        <ModuleInputWrapper isDisabled={isDisabled}
                                            fieldValue={getFieldValue(notificationTextField)}
                                            handleChange={(value) => {
                                                handleValueChange(value, MODULES.MODEL_NOTIFICATION_TEXT.API_NAME);
                                                validateInput(value, notificationTextField);
                                            }}
                                            inputType={MODULES.MODEL_NOTIFICATION_TEXT.UI_NAME}
                                            saveModuleTrigger={saveModuleTrigger}
                                            error={isError(notificationTextField, moduleEmptyFields)}
                        />
                    </HorizontalModuleWrapper>
                    <HorizontalModuleWrapper paddingRight={'3rem'}>
                        <ModuleInputWrapper marginRight={'3rem'}
                                            isDisabled={true}
                                            fieldValue={getFieldValue(notificationImageField)}
                                            inputType={MODULES.MODEL_NOTIFICATION_IMAGE.UI_NAME}
                                            saveModuleTrigger={saveModuleTrigger}
                                            error={isError(notificationImageField, moduleEmptyFields)}
                                            placeholder={PLACEHOLDERS.NO_FILE_SPECIFIED}
                        />
                        <Button Smaller={true} onClick={(e) => {
                            e.preventDefault();
                            manageFileTrigger(notificationImageField);
                        }}>Dodaj zdjęcie</Button>
                    </HorizontalModuleWrapper>
                    <HorizontalModuleWrapper>
                    </HorizontalModuleWrapper>
                </ModuleContentWrapper>
                <ActionContainer isDisabled={isDisabled} setIsDisabled={setIsDisabled}
                                 removeModuleTrigger={removeModuleTrigger} idToRemove={module._id}
                                 saveModuleTrigger={saveModuleTrigger}
                                 resetValuesToDefault={resetValuesToDefault}
                                 module={module}
                />
            </ModuleWrapper>
        </DnDWrapper>
    )
};
export default PoiModule;