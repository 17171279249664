import styled from "styled-components";
import InfoParagraph from "../atoms/paragraphs/InfoParagraph";
import Button from "../atoms/buttons/Button";
import React from "react";

const UserActionContainer = styled.div`
   width: 100%;
   height: 5rem;
   min-height: 5rem;
   display: flex;
   align-items: center;
   margin-top: 1rem;
   justify-content: space-between;
   margin-bottom: 3rem;
`;

const ActionButtonWrapper = styled.div`
   width: 20%;
   height: 100%;
   display: flex;
   align-items: center;
   //background-color: orange;
   justify-self: flex-end;
   justify-content: flex-end;
`;


const getButtonByActionType = (actionType, action) => {

    switch (actionType) {
        case 'add':
            return <Button actionType={actionType} onClick={action}>Dodaj moduły</Button>;
        case 'save':
            return <Button actionType={actionType} onClick={action}>Zapisz kolejność</Button>;
        default:
            return null;
    }
};

const ActionBar = ({noModules, action, actionType}) => (
    <UserActionContainer>
        {actionType === 'add' ?
            <>
                {noModules ? <InfoParagraph>Ta kategoria nie posiada modułów. Kliknij przycisk Dodaj moduły aby ją
                        uzupełnić.</InfoParagraph> :
                    <InfoParagraph>Przeciągnij element klikając na nagłówek modułu, aby zmienić kolejność
                        listy.</InfoParagraph>}
            </> :
            <InfoParagraph moreImportant>Pamiętaj aby zapisąc kolejność listy, inaczej wprowadzone zmiany zostaną utracone.</InfoParagraph>
        }
        <ActionButtonWrapper>
            {getButtonByActionType(actionType, action)}
        </ActionButtonWrapper>
    </UserActionContainer>
);

export default ActionBar;