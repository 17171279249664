export const ACTION_TYPES = {

    // --- AUTH ---
    AUTHENTICATE_REQUEST: 'AUTHENTICATE_REQUEST',
    AUTHENTICATE_SUCCESS: 'AUTHENTICATE_SUCCESS',
    AUTHENTICATE_FAILURE: 'AUTHENTICATE_FAILURE',
    AUTHENTICATE_ON_FOCUS: 'AUTHENTICATE_ON_FOCUS',

    // --- USER ---
    LOGOUT: 'LOGOUT',

    // --- GENERIC FETCH ---
    FETCH_REQUEST: 'FETCH_REQUEST',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    FETCH_FAILURE: 'FETCH_FAILURE',

    // --- MENU ---
    MENU_FETCH_SUCCESS: 'FETCH_SUCCESS',
    MENU_FETCH_AFTER_EDIT: 'MENU_FETCH_AFTER_EDIT',

    // --- MODULES ---
    FETCH_MODULES_SUCCESS: 'FETCH_MODULES_SUCCESS',
    ADD_MODULE: 'ADD_MODULE',
    REMOVE_MODULE: 'REMOVE_MODULE',
    SAVE_MODULE: 'SAVE_MODULE',
    TOGGLE_MODULE_EDIT: 'TOGGLE_MODULE_EDIT',
    SAVE_CREATED_MODULE: 'SAVE_CREATED_MODULE',
    MODULE_CANCEL_ADDING: 'MODULE_CANCEL_ADDING',
    EDIT_MODULE_DATA: 'EDIT_MODULE_DATA',
    MODULE_ADD_FILE: 'MODULE_ADD_FILE',
    EDIT_MODULE_REQUEST: 'EDIT_MODULE_REQUEST',
    EDIT_MODULE_RESPONSE: 'EDIT_MODULE_RESPONSE',

    // --- FILES ---
    IMPORT_FILES: 'IMPORT_FILES',
    CLEAR_IMPORTED_FILES: 'CLEAR_IMPORTED_FILES',
    HANDLE_FIELD_VALUE_CHANGE: 'HANDLE_FIELD_VALUE_CHANGE',

    // --- VIEW ---
    SET_CURRENT_PATH: 'SET_CURRENT_PATH',
    SET_CURRENT_NAVIGATION_ITEM: 'SET_CURRENT_NAVIGATION_ITEM',

    // --- ERROR MODAL ---
    SET_API_ERROR: 'SET_API_ERROR',

    // --- LINK MODAL ---
    SET_ALL_NAVIGATION_ITEMS: 'SET_ALL_NAVIGATION_ITEMS',
    SET_NEW_NAVIGATION_ITEM: 'SET_NEW_NAVIGATION_ITEM',

    // --- REDIRECT STACK ---
    REDIRECT_STACK_POP: 'REDIRECT_STACK_POP',
    REDIRECT_STACK_PUSH: 'REDIRECT_STACK_PUSH',
    REDIRECT_STACK_SET: 'REDIRECT_STACK_SET',
    REDIRECT_STACK_RESET: 'REDIRECT_STACK_RESET',
    REDIRECT_STACK_CURRENT_EDIT: 'REDIRECT_STACK_CURRENT_EDIT',


    // --- DND ACTIONS ----
    SET_SHOW_SAVE_ORDER_BUTTON: 'SET_SHOW_SAVE_ORDER_BUTTON'
};