import styled from "styled-components";
import React from "react";
import SidebarTemplate from "./SidebarTemplate";
import {routes} from "../routes/index";
import PrivateRoute from "../routes/PrivateRoute";
import NavigationItem from "../components/views/NavigationItem/NavigationItem";

const App = styled.div`
   min-height: 100vh;
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: row;
`;

const AppTemplate = () => {

    return (
        <App>
            <SidebarTemplate>
                <PrivateRoute path={routes.navigation_item} component={NavigationItem}/>
                {/*<PrivateRoute exact path={routes.add} component={AddPage}/>*/}
                {/*Way of adding nav-items changed*/}
            </SidebarTemplate>
        </App>
    )
};
export default AppTemplate;