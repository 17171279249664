import React from "react";
import styled from "styled-components";
import {Icon} from "../atoms/logotypes/Icon";
import {ACTION_TYPES} from "../../actions/actionTypes";
import Button from "../atoms/buttons/Button";
import {useDispatch} from "react-redux";
import jwtDecode from 'jwt-decode';
import {getToken} from "../../actions";

const UserInfoWrapper = styled.div`
   height: 6rem;
   display: flex;
   position:absolute;
   top: 0;
   right: 0;
   align-items: center;
   justify-content: space-between;
   padding-top: 1rem;
`;

const IconContainer = styled(Icon)`
   display: flex;
   width: 4rem;
   height: 4rem;
`;

const LoginContainer = styled.div`
   display: flex;
   width: auto;
   margin: 0 3rem 0 1rem;
   font-size: ${({ theme }) => theme.fontSize.s};
`;

const UserInfo = () => {

    const dispatch = useDispatch();
    const getUserLogin = () => {
        const token = getToken();
        if (token) return jwtDecode(token).login;
    };

    return (
        <UserInfoWrapper>
            <IconContainer/>
            <LoginContainer>
                {getUserLogin()}
            </LoginContainer>
            <Button Logout={true} action={() => dispatch({type: ACTION_TYPES.LOGOUT})}>Wyloguj</Button>
        </UserInfoWrapper>

    )
};
export default UserInfo;