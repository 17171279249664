import styled from "styled-components";
import React from "react";
import app_logo_white from "../../assets/images/app-logo-white.png"
import Menu from "./Menu";

const SidebarWrapper = styled.div`
   width: 14%;
   min-width: 20rem;
   height: 100vh;
   display: flex;
   background-color: ${({theme}) => theme.app_black};
   padding-left: 3.5rem;
   flex-direction: column;
   position:fixed;
`;

const Logo = styled.div`
   width: 80%;
   background-image: url(${app_logo_white});
   background-size: cover;
   background-position: 50% 50%;
   background-repeat: no-repeat;
   display: flex;
   margin-top: 3rem;
   margin-left: -0.5rem;
   max-width: 237px;
   
   &:after{
   content: '';
   display: block;
   padding-bottom: 100%;
   }
`;


const Sidebar = () => {

    return(
    <SidebarWrapper>
        <Logo/>
        <Menu/>
    </SidebarWrapper>

)};
export default Sidebar;