import styled from "styled-components";
import React from "react";
import MenuItem from "../atoms/buttons/MenuItem";
import {useSelector} from "react-redux";

const MenuWrapper = styled.ul`
   width: 95%;
   height: 50%;
   display: flex;
   margin-top: 5rem;
   flex-direction: column;
   list-style: none;
   text-decoration: none;
   padding: 0;
   color: white;
`;

const Menu = () => {
    const navigationItemsList = useSelector(state => state.navigationItemsList);

    return (
        <MenuWrapper>
            {navigationItemsList && navigationItemsList.map(item => <MenuItem key={item._id} item={item}/>)}
        </MenuWrapper>
    )
};
export default Menu;

