import React from 'react';
import styled, {css} from "styled-components";
import {InputDescription} from "../../molecules/ModuleInputWrapper";

const DropdownWrapper = styled.div`
   margin-right: ${({marginRight}) => marginRight || '0'};
   display: flex;
   flex-direction: column;
   width: 100%;  
   position: relative;
`;

const StyledSelect = styled.select`
   width: 100%;
   height: 3rem;
   border: 1px solid #D5D5D5;
   background-color: white;
   padding-left: 25px;
   border-radius: 1rem;
    &:focus{
      outline: none;
    }
    
    ${({error}) =>
    error &&
    css`
        border: 1px solid ${({ theme }) => theme.app_orange};
   `}
    
    ${({isTypeValid}) =>
    isTypeValid === false &&
    css`
       border: 1px solid ${({ theme }) => theme.app_orange};
   `}
`;

export const ErrorDesription = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  font-size: 1rem;
  color: ${({ theme }) => theme.app_orange};
  position:absolute;
  bottom: -1.7rem;
`;

const Dropdown = ({value, handleChange, options, marginRight, inputType,isTypeValid,error}) => {
    return (
        <DropdownWrapper error={error} marginRight={marginRight}>
            <InputDescription error={error} isTypeValid={isTypeValid}>{inputType}</InputDescription>
            <StyledSelect error={error} isTypeValid={isTypeValid} value={value} onChange={(e) => {
                handleChange(e.target.value);
            }}>
                {options.map(opt => <option key={opt.TYPE} value={`${opt.TYPE}`}>{opt.UI_NAME}</option>)}
            </StyledSelect>
            {error && <ErrorDesription>Rodzaj niezgodny z typem wybranego linku.</ErrorDesription>}

        </DropdownWrapper>
    );
};

export default Dropdown;
