export const MODULES = {
    HEADER: {
        UI_NAME: 'Nagłówek',
        API_NAME: 'header',
    },
    SHORT_MENU: {
        UI_NAME: 'Spis treści',
        API_NAME: 'short_menu',
    },
    SHORT_DESCRIPTION: {
        UI_NAME: 'Krótki opis',
        API_NAME: 'short_description',
    },
    HEADER_TEXT: {
        UI_NAME: 'Tekst z nagłówkiem',
        API_NAME: 'header_text',
    },
    TEXT: {
        UI_NAME: 'Tekst',
        API_NAME: 'text',
    },
    GALLERY: {
        UI_NAME: 'Galeria',
        API_NAME: 'gallery',
        API_FIELD_NAME: 'photos',
    },
    // --- INSIDE GALLERY ---
    PHOTOS: {
        UI_NAME: 'Galeria',
        API_NAME: 'photos',
    },
    DESCRIPTION: {
        UI_NAME: 'Opis',
        API_NAME: 'description',
    },
    // ----------------------
    AUDIO: {
        UI_NAME: 'Audio',
        API_NAME: 'audio',
    },
    VIDEO: {
        UI_NAME: 'Wideo',
        API_NAME: 'video',
    },
    LIST: {
        UI_NAME: 'Lista',
        API_NAME: 'list',
    },
    PATH: {
        UI_NAME: 'Ścieżka',
        API_NAME: 'path',
    },
    LINK: {
        UI_NAME: 'Link',
        API_NAME: 'link',
    },
    PHOTO: {
        UI_NAME: 'Zdjęcie',
        API_NAME: 'photo',
    },
    FILTER: {
        UI_NAME: 'Filtr',
        API_NAME: 'filter',
    },
    POI: {
        UI_NAME: 'Poi',
        API_NAME: 'poi',
    },
    LOCATION: {
        UI_NAME: 'Lokalizacja',
        API_NAME: 'location',
    },
    MODEL_NAME: {
        UI_NAME: 'Nazwa modelu 3D',
        API_NAME: '3d_model_name',
    },
    MODEL_3D: {
        UI_NAME: 'Model 3D',
        API_NAME: '3d_model',
        BIN: 'Model *.bin',
        GLTF: 'Model *.gltf',
        PNG: 'Model *.png'
    },
    MODEL_NOTIFICATION_DISTANCE: {
        UI_NAME: 'Odległość otrzymania notyfikacji (m)',
        API_NAME: '3d_model_notification_distance',
    },
    MODEL_NOTIFICATION_TITLE: {
        UI_NAME: 'Nagłówek notyfikacji',
        API_NAME: '3d_model_notification_title',
    },
    MODEL_NOTIFICATION_TEXT: {
        UI_NAME: 'Treść notyfikacji',
        API_NAME: '3d_model_notification_text',
    },
    MODEL_NOTIFICATION_IMAGE: {
        UI_NAME: 'Ikona notyfikacji',
        API_NAME: '3d_model_notification_image',
    },
    SHORT_LINK: {
        UI_NAME: 'Link - przycisk',
        API_NAME: 'short_link',
    },
    LINK_TYPE: {
        UI_NAME: 'Rodzaj odnośnika',
        API_NAME: 'link_type',
    },
    SHORT_HEADER: {
        UI_NAME: 'Krótki nagłówek',
        API_NAME: 'short_header',
    },
    ITEMS: {
        UI_NAME: 'elementy',
        API_NAME: 'items',
    },
    AR: {
        UI_NAME: 'AR',
        API_NAME: 'ar',
    },
};

export const INPUT_TYPES = {
    TITLE: 'Tytuł',
    BG_IMG: 'Zdjęcie w tle',
    SHORT_DESCRIPTION: 'Krótki opis',
    TEXT: 'Treść',
    HEADER: 'Nagłówek',
    FILE: 'Plik',
    TAG: 'Tag',
    LONGITUDE: 'Długość',
    LATITUDE: 'Szerokość',
};


export const MOCK_MODULES = {
    header: {
        fields: [
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'header'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'short_description'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'photo'
            }
        ],
        type: 'header',
        order: null
    },
    text: {
        fields: [
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'text'
            },
        ],
        type: 'text',
        order: null
    },
    header_text: {
        fields: [
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'header'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'text'
            },
        ],
        type: 'header_text',
        order: null
    },
    audio: {
        fields: [
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'header'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'link'
            },
        ],
        type: 'audio',
        order: null
    },
    video: {
        fields: [
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'header'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'link'
            },
        ],
        type: 'video',
        order: null
    },
    gallery: {
        fields: [
            {
                fieldValues: [],
                name: 'photos'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    },
                ],
                name: 'description'
            },
        ],
        type: 'gallery',
        order: null
    },
    photo: {
        fields: [
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'photo'
            },
        ],
        type: 'photo',
        order: null,
        tag: "",
    },
    short_menu: {
        fields: [],
        type: 'short_menu',
        order: null
    },
    filter: {
        fields: [],
        type: 'filter',
        order: null
    },
    list: {
        fields: [
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'items'
            },
        ],
        type: 'list',
        order: null
    },
    path: {
        fields: [
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'location'
            },
        ],
        type: 'path',
        order: null
    },
    poi: {
        fields: [
            {
                fieldValues: [
                    {
                        value: "0.00,0.00"
                    }
                ],
                name: 'location'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: '3d_model_name'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    },
                    {
                        value: ""
                    },
                    {
                        value: ""
                    }
                ],
                name: '3d_model'
            },
            {
                fieldValues: [
                    {
                        value: "1"
                    }
                ],
                name: '3d_model_notification_distance'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: '3d_model_notification_title'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: '3d_model_notification_text'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: '3d_model_notification_image'
            }
        ],
        type: 'poi',
        order: null
    },
    short_link: {
        fields: [
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'text'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: 'link'
            },
            {
                fieldValues: [
                    {
                        value: "content"
                    }
                ],
                name: 'link_type'
            }
        ],
        type: 'short_link',
        order: null
    },
    link: {
        fields: [
            {
                fieldValues: [

                    {
                        value: ""
                    }
                ],
                name: 'short_header'
            },
            {
                fieldValues: [

                    {
                        value: ""
                    }
                ],
                name: 'header'
            },
            {
                fieldValues: [

                    {
                        value: ""
                    }
                ],
                name: 'short_description'
            },
            {
                fieldValues: [

                    {
                        value: ""
                    }
                ],
                name: 'photo'
            },
            {
                fieldValues: [

                    {
                        value: ""
                    }
                ],
                name: 'link'
            },
            {
                fieldValues: [

                    {
                        value: "content"
                    }
                ],
                name: 'link_type'
            }
        ],
        type: "link",
        order: null
    },
    ar: {
        fields: [
            {
                fieldValues: [
                    {
                        value: ""
                    }
                ],
                name: '3d_model_name'
            },
            {
                fieldValues: [
                    {
                        value: ""
                    },
                    {
                        value: ""
                    },
                    {
                        value: ""
                    }
                ],
                name: '3d_model'
            },
        ],
        type: 'ar',
        order: null
    },
};

export const POPUP_MODULES = [
    {
        type: 'header',
        ui_name: 'Nagłówek'
    },
    {
        type: 'text',
        ui_name: 'Tekst'
    },
    {
        type: 'header_text',
        ui_name: 'Tekst z nagłówkiem'
    },
    {
        type: 'audio',
        ui_name: 'Audio'
    },
    {
        type: 'video',
        ui_name: 'Wideo'
    },
    {
        type: 'photo',
        ui_name: 'Zdjęcie'
    },
    {
        type: 'short_menu',
        ui_name: 'Spis treści'
    },

    {
        type: 'filter',
        ui_name: 'Filtr'
    },
    {
        type: 'list',
        ui_name: 'Lista'
    },

    {
        type: 'gallery',
        ui_name: 'Galeria'
    },
    {
        type: 'path',
        ui_name: 'Sieżka'
    },
    {
        type: 'poi',
        ui_name: 'POI'
    },

    {
        type: 'short_link',
        ui_name: 'Link - przycisk'
    },
    {
        type: 'link',
        ui_name: 'Link'
    },
    {
        type: 'ar',
        ui_name: 'AR'
    },
];


export const PLACEHOLDERS = {
    NO_LINK_SPECIFIED: 'Nie wybrano linku...',
    NO_FILE_SPECIFIED: 'Nie wybrano pliku...'
};


export const MODULE_TYPES = [
    {
        TYPE: 'content',
        UI_NAME: 'Treść'
    },
    {
        TYPE: 'map',
        UI_NAME: 'Google Maps'
    },
    {
        TYPE: 'ar',
        UI_NAME: 'Model rozszerzonej rzeczywistości'
    }
];