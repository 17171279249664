import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {routes} from '../routes';
import {useSelector} from "react-redux";
import {getToken} from "../actions";

const PrivateRoute = ({ component: Component, ...restProps }) => {

    let token = getToken();
    let isUserLogged = useSelector(state => state.isUserLogged);

    return (
        <Route
            {...restProps}
            render={props => {
                return token && isUserLogged ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: routes.login, state: { from: props.location } }} />
                );
            }}
        />
    );
};

export default PrivateRoute;
