export const MODAL_TYPES = {
    ADD_MODULE: {
        TYPE: 'ADD_MODULE',
        MESSAGE: 'Wybierz typ modułu'
    },

    REMOVE_MODULE: {
        TYPE: 'REMOVE_MODULE',
        MESSAGE: 'Czy napewno chcesz usunąć wybrany moduł?'
    },

    MANAGE_IMAGES: {
        TYPE: 'MANAGE_IMAGES',
        MESSAGE: 'Zarządzanie multimediami'
    },
    SET_LINK: {
        TYPE: 'SET_LINK',
        MESSAGE: 'Wybierz link'
    },
    REMOVE_NAV_ITEM: {
        TYPE: 'REMOVE_NAV_ITEM',
        MESSAGE: 'Czy napewno chcesz usunąć odnośnik? Powiązania linków zostaną utracone.'
    },
};