import styled, {css} from "styled-components";

export const ModuleWrapper = styled.div`
  flex: 0 0 auto;
  width: 100%;
  min-height: ${({minHeight}) => minHeight || '22rem'};
  height: auto;
  background-color: white;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.04), 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  display: flex;
  padding: 2rem;
  position: relative;
  
  ${({isSmall}) =>
    isSmall &&
    css`
            min-height: 10rem;
   `}
`;