import React from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import {ACTION_TYPES} from "../../../actions/actionTypes";
import {connect} from "react-redux";
import PortalBackground from "../../atoms/wrappers/PortalBackground";

const modalRoot = document.getElementById('modal-portal');

const PopUpContainer = styled.div`
   width: 50rem;
   min-height: 10rem;
   background-color: white;
   border-radius: 1rem;
   display: flex;
   flex-direction: column;
   margin: 0 auto;
   justify-self: center;
   align-self: center;
   z-index: 999999;
   position: absolute; 
   padding: 3rem;
   align-items: center;
   text-align: center;   
`;

const ModalHeaderWrapper = styled.div`
   display:flex;
   width: auto;
   height: auto;
   justify-content: center;
   margin-bottom: 2rem;
`;

const ModalHeader = styled.div`
   display: flex;
   margin: 0 auto;
   font-weight: ${({theme}) => theme.fontWeight.medium};
   font-size: ${({theme}) => theme.fontSize.xl};
   align-self: center;
   justify-content: center;
   font-family: 'Anton', sans-serif;
`;


class ModalError extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.state = {seconds: 5};
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
        this.interval = setInterval(() => {
            if(this.state.seconds > 1){
                this.setState({seconds: this.state.seconds - 1});
            }
        }, 1000);
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
        clearInterval(this.interval);
    }



    render() {
        const {isError, logoutAction} = this.props;
        let {seconds} = this.state;
        if (isError) setTimeout(() => logoutAction(), 5000);

        return ReactDOM.createPortal(
            <PortalBackground>
                <PopUpContainer>
                    <ModalHeaderWrapper>
                        <ModalHeader>{isError.code} ERROR</ModalHeader>
                    </ModalHeaderWrapper>
                    {isError.message}
                    <p>{`Zostaniesz automatycznie wylogowany w ciągu ${seconds}s...`}</p>
                </PopUpContainer>
            </PortalBackground>,
            this.el,
        );
    }
}

const mapDispatchToProps = dispatch => ({
    logoutAction: () => dispatch({type: ACTION_TYPES.LOGOUT})
});

export default connect(
    null,
    mapDispatchToProps,
)(ModalError);