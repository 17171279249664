import styled, {css} from 'styled-components';
import React from "react";
import {INPUT_TYPES} from "../../utils/modules";
import TextArea from "../atoms/inputs/TextArea";
import {ErrorDesription, InputDescription} from "./ModuleInputWrapper";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position:relative;
  
  ${({type}) =>
    type === INPUT_TYPES.SHORT_DESCRIPTION &&
    css`
     margin-right: 0rem;
   `}
`;


const ModuleTextAreaWrapper = ({type, fieldValue,handleChange,error,...restProps}) => {
        return (
            <Wrapper type={type} >
                <InputDescription error={error} >{type}</InputDescription>
                <TextArea value={fieldValue} error={error} onChange={(e) => handleChange(e.target.value)} {...restProps}/>
                {error && <ErrorDesription>Pole nie może być puste.</ErrorDesription>}
            </Wrapper>
        )
    }
;
export default ModuleTextAreaWrapper;