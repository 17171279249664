import styled, {css} from 'styled-components';
import React from "react";

import login_icon from '../../../assets/icons/login-button.svg'
import logout_icon from '../../../assets/icons/logout-button-icon.svg';
import add_modules_icon from '../../../assets/icons/add-modules-icon.svg';

const ButtonWrapper = styled.button`
  width: auto;
  min-width: 15rem;
  padding: 0 4.5rem 0 4.5rem;
  height: ${({height}) => height || '3.5rem'};
  border: none;
  border-radius: 8px;
  font-weight: ${({theme}) => theme.fontWeight.medium};
  font-size: ${({theme}) => theme.fontSize.l};
  background-color: black;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.04), 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: center;
  text-decoration: none;
  &:focus{
      outline: none;
  }
  
  &:hover{
  color: ${({theme}) => theme.app_orange};
  }
  
  ${({Logout}) =>
    Logout === true &&
    css`
      &:hover{
          color: ${({theme}) => theme.app_grey};
      }
   `}
   
    &:disabled{
          color: ${({theme}) => theme.app_grey};
          pointerEvents: "none";
          cursor: initial;
      &:hover{
          color: ${({theme}) => theme.app_grey};
      }
      }

   
  ${({Login}) =>
    Login === true &&
    css`
      font-size: ${({theme}) => theme.fontSize.s};
        width: 18rem;
        height: 4rem;
    `}
    
  ${({Smaller}) =>
    Smaller === true &&
    css`
      font-size: ${({theme}) => theme.fontSize.s};
        padding: 0 2.5rem 0 2.5rem;
        height: ${({height}) => height || '3rem'};
    `}
`;

const TextWrapper = styled.div`
  display: flex;
`;

const ButtonIcon = styled.div`
  display: flex;
  height: ${({height}) => height || '3.5rem'};
  width: ${({height}) => height || '3.5rem'};
  background-image: url(${login_icon});
  background-size: 2rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: ${({theme}) => theme.app_orange};
  border-radius: 8px;
  position:absolute;
  left: -1px;
  top: 0;
  transition: left 0.15s ease-in-out;
  -webkit-transition: left 0.15s ease-in-out;;
 
  ${ButtonWrapper}:hover &{
  left: calc(100% - ${({height}) => height || '3.5rem'} + 1px);
  }
  
   ${({Logout}) =>
    Logout === true &&
    css`
      background-color: ${({theme}) => theme.app_grey};
      background-image: url(${logout_icon});
      background-size: 1.5rem;

    `}
   
  ${({Login}) =>
    Login === true &&
    css`
     width: 4rem;
     height: 4rem;
     
     ${ButtonWrapper}:hover &{
      left: calc(100% - 4rem);
      }
    `}
    
   ${({isDisabled}) =>
    isDisabled === true &&
    css`
           background-color: ${({theme}) => theme.app_grey};
           left: 0;
           ${ButtonWrapper}:hover &{
              left: 0;
          }

    `}
  
    ${({Smaller}) =>
    Smaller === true &&
    css`
        height: ${({height}) => height || '3rem'};
        width: ${({height}) => height || '3rem'};
        
        ${ButtonWrapper}:hover &{
            left: calc(100% - ${({height}) => height || '3rem'});
          }
    `}
    

    ${({actionType}) =>
    actionType === "add" &&
    css`
          background-image: url(${add_modules_icon});
    `}

`;

const Button = ({children, action, isDisabled, ...restProps}) => (
    <ButtonWrapper onClick={action} {...restProps} disabled={isDisabled}>
        <ButtonIcon style={{pointerEvents: "none"}} isDisabled={isDisabled} {...restProps}/>
        <TextWrapper>
            {children}
        </TextWrapper>
    </ButtonWrapper>
);

export default Button;