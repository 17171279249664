import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import MainTemplate from "../../templates/MainTemplate";
import {routes} from "../../routes/index";
import AuthPage from "./Auth/AuthPage";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/es/integration/react";
import configureStore from "../../store/configureStore";
import PrivateRoute from "../../routes/PrivateRoute";
import NoMatch from "./RouteErrors/NoMatch";
import AppTemplate from "../../templates/AppTemplate";

const {store, persistor} = configureStore();


const Root = () => {

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <MainTemplate>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path={routes.login} render={() => <AuthPage/>}/>
                            <PrivateRoute path={routes.navigation_item} component={AppTemplate}/>

                            {/*--- 404 unexisting routes ---*/}
                            <Route component={NoMatch}/>
                        </Switch>
                    </BrowserRouter>
                </MainTemplate>
            </PersistGate>
        </Provider>
    )
};
export default Root;